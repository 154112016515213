import type { FC } from 'react';

import useAppStoreVos from '../service/store/appVos';

import './Highlighter.scss';

interface IHighlighter {
  text: string;
  disableHighlight?: boolean;
}

const Highlighter: FC<IHighlighter> = ({
  text,
  disableHighlight = false
}) => {
  const { filter } = useAppStoreVos(state => state);
  const { search: searchTerm } = filter;

  if (!searchTerm || disableHighlight) {
    return <span>{text}</span>;
  }

  const regex = new RegExp(`(${searchTerm})`, 'gi');
  const parts = text.split(regex);

  return (
    <span className="highlight-wrap">
      {parts.map(part =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span className="highlight-mark" key={`${searchTerm}-${part}`}>{part}</span>
        ) : (
          <span className="highlight-text" key={`${searchTerm}-${part}`}>{part}</span>
        )
      )}
    </span>
  );
};

export default Highlighter;
